import React, { useState, useContext, useEffect } from "react"
import useButtonStates from "../../hooks/useButtonStates"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import Button from "../Button/Button"
interface Props {
  description: string
  headline: string
  registrationLink: {
    target: string
    title: string
    url: string
  }
}

const VendowRegistration: React.FC<Props> = ({
  description,
  headline,
  registrationLink,
}) => {
  const linkText = registrationLink?.title
  const [text, setText] = useState({ description, headline, linkText })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ description, headline, linkText })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([description, headline, linkText])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <article css={[tw`max-w-4xl mx-auto mt-14`]}>
      {text?.headline && 
        <SectionHeading>{text?.headline}</SectionHeading>
      }
      <p
        css={[tw`mt-6 text-center`]}
        dangerouslySetInnerHTML={{ __html: text?.description }}
      />
      {linkText && 
        <Button link={registrationLink?.url} text={linkText} css={tw`block w-fit mx-auto`} />
      }
    </article>
  )
}

export default VendowRegistration
