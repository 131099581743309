import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import { CardLink } from "../Card"
interface Props {
  businessOpportunityCards: Array<{
    cardType: string
    title: string
    backgroundImage?: {
      localFile: {
        publicURL: string
        childImageSharp: {
          gatsbyImageData: any
        }
      }
    }
    link: {
      target: string
      title: string
      url: string
    }
    icon: {
      localFile: {
        publicURL: string
      }
    }
    downloadFile: {
      localFile: {
        publicURL: string
      }
    }
  }>
  headline: string
}

const BusinessOpportunities: React.FC<Props> = ({
  headline,
  businessOpportunityCards,
}) => {
  const [text, setText] = useState({ headline })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <article css={[tw`mt-14 max-w-4xl mx-auto`]}>
      <SectionHeading>{text?.headline}</SectionHeading>
      <div css={[tw`flex flex-wrap justify-center gap-5 mt-8 px-2 md:gap-3 lg:gap-5`]}>
        {businessOpportunityCards?.map((item, index) => (
          <CardLink {...item} key={index} />
        ))}
      </div>
    </article>
  )
}

export default BusinessOpportunities
