import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useContext, useEffect, useState } from "react"
import { ProcurementTemplatePageData, TemplateProps } from "../templates"
import ProcurementHeadline from "../../components/Procurement/ProcurementHeadline"
import BusinessOpportunities from "../../components/Procurement/BusinessOpportunities"
import VendowRegistration from "../../components/Procurement/VendowRegistration"
import tw from "twin.macro"
import { SEO } from "../../components/SEO"
import ImageHero from "../../components/Hero/ImageHero"
import { H1 } from "../../components/Typography"
import { Image } from "../../components/Image"
import TranslateClient from "../../clients/TranslateClient"
import { LanguageContext } from "../../context/Language"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")


interface Props {}

const procurement: React.FC<Props> = ({
  data,
  location,
}: TemplateProps<ProcurementTemplatePageData>) => {
  const { title, content, featuredImage, template } = data.wpPage
  const { businessOpportunitiesSection, vendorRegistrationSection } =
    template.procurementPage

  const image = getImage(
    featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  )

  const [text, setText] = useState({ title })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <section>
        <div css={[tw`relative`]}>
          <H1
            css={[
              tw`absolute text-white text-4xl font-extrabold sm:text-5xl xl:text-6xl z-20 w-full h-full flex items-center justify-center`,
              "text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);",
            ]}
          >
            {text?.title}
          </H1>
          <Image
            src={image}
            publicURL={featuredImage?.node?.localFile?.publicURL}
            alt=""
            role="presentation"
            objectPosition="22% 50%"
            css={[tw`h-80`]}
          />
        </div>
        <ProcurementHeadline title="" content={content} />
        <BusinessOpportunities {...businessOpportunitiesSection} />
        <VendowRegistration {...vendorRegistrationSection} />
      </section>
    </div>
  )
}

export default procurement

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      template {
        ... on WpTemplate_Procurement {
          procurementPage {
            businessOpportunitiesSection {
              businessOpportunityCards {
                cardType
                title
                backgroundImage {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                downloadFile {
                  localFile {
                    publicURL
                  }
                }
                link {
                  target
                  title
                  url
                }
                icon {
                  localFile {
                    publicURL
                  }
                }
              }
              headline
            }
            vendorRegistrationSection {
              description
              headline
              registrationLink {
                target
                title
                url
              }
            }
          }
        }
      }
    }
  }
`
